import { template as template_b66d32e46f5242b2afbc736da8ac66c1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b66d32e46f5242b2afbc736da8ac66c1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
